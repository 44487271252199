import React from "react"
import SinglePageHeader from "../components/SinglePageLayout"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Careers() {
  const { t } = useTranslation()

  return (
    <SinglePageHeader title={t("careers")} bg="/assets/img/careers.jpg">
      <div className="ich-container">
        <h3>{t("careers")}</h3>
        <p>{t("careers-p1")}</p>
        <p>
          {t("careers-p2")}{" "}
          <a className="ich-pink-link" href="mailto:contact@ich-group.com">
            contact@ich-group.com
          </a>
          .
        </p>
      </div>
    </SinglePageHeader>
  )
}
